import * as Sentry from '@sentry/vue';

import {
  AUTH_NAMESPACE_PATH,
  HISTORY_NAMESPACE_PATH,
  USER_NAMESPACE_PATH,
} from '../namespaces.type';
import {
  BADGE_STATES,
  BADGE_STATES_LAST_CHANGED,
  BADGE_TIMEOUT_REF,
  FULL_NAME,
  IS_ADMIN,
  IS_ADMIN_OR_ROID,
  IS_ADMIN_OR_SUPPORT,
  IS_ROID,
  IS_SUPPORT,
  LAST_CHECKED_TASKS,
  LATEST_USER_TRIGGERED_TASK_TIME,
  ROLES,
  SETTINGS_DARK_THEME,
  SETTINGS_NUMBER_OF_ITEMS_IN_HISTORY,
  USER,
  USER_SETTINGS,
} from '../getters.type';
import {
  BadgeState,
  BadgeStates,
  TasksState,
  Theme,
  UserSettings,
  UserState,
} from '../types';
import {
  CHANGE_NUMBER_OF_ITEMS_IN_HISTORY,
  CHANGE_THEME,
  CLEAR_USER_STATE,
  FETCH_TASKS_STATE,
  FETCH_USER_INFO,
  LOGOUT,
  UPDATE_LAST_CHANGED_TASKS,
  UPDATE_NUMBER_OF_ITEMS_IN_HISTORY,
} from '../actions.type';
import {
  RESET_STATE,
  SET_BADGE_STATES,
  SET_BADGE_STATES_LAST_CHANGED,
  SET_LAST_CHECKED_TASKS,
  SET_LATEST_USER_TRIGGERED_TASK_TIME,
  SET_NUMBER_OF_ITEMS_IN_HISTORY,
  SET_THEME_DATA,
  SET_USER_INFO,
} from '../mutations.type';

import { AxiosResponse } from 'axios';
import { User } from '../../api/interfaces';
import { fetchRepeatedly } from '../../utils/other';
import { repositories } from '@/api/ApiFactory';

function importantBadgeStateChange(s1: BadgeStates, s2: BadgeStates) {
  return (
    s1.succeeded.value !== s2.succeeded.value ||
    s1.failed.value !== s2.failed.value ||
    s1.revoked.value !== s2.revoked.value ||
    s1.ongoing.value !== s2.ongoing.value ||
    s1.pending.value !== s2.pending.value
  );
}

/*
 * We need this function to generate initial settings. Otherwise we would
 * need to create a deep copy of the settings.
 */
function getInitialSettings(): UserSettings {
  return {
    theme: {
      dark: false,
    },
    numberOfItemsInHistory: 5,
  };
}

function getInitialBadgeState(color: string | null = null): BadgeState {
  return { value: '0', color: color };
}

function getInitialBadgeStates(): BadgeStates {
  return {
    succeeded: getInitialBadgeState('success'),
    failed: getInitialBadgeState('error'),
    revoked: getInitialBadgeState('warning'),
    ongoing: getInitialBadgeState('info'),
    pending: getInitialBadgeState('#a01497'),
  };
}

const FETCH_TASKS_INTERVAL = 10000;

const userState: UserState = {
  user: null,
  isAdmin: false,
  isRoid: false,
  isSupport: false,
  tasksInfo: {
    badges: {
      states: getInitialBadgeStates(),
      timeoutRef: null,
      lastChanged: null,
    },
    lastCheckedTasks: null,
    latestUserTriggeredTaskTime: null,
  },
  // don't read settings directly from the state, use getter instead because
  // the returned value depends on whether the user is logged in or not
  settings: getInitialSettings(),
};

const getters = {
  [FULL_NAME](state: UserState): string | null {
    return state.user !== null
      ? `${state.user!.first_name} ${state.user!.last_name}`
      : null;
  },
  [USER_SETTINGS](state: UserState): UserSettings {
    return state.user ? state.settings : getInitialSettings();
  },
  [USER](state: UserState): User | null {
    return state.user;
  },
  [ROLES](state: UserState): string[] {
    const roles = [];
    if (state.isAdmin) {
      roles.push('admin');
    }
    if (state.isSupport) {
      roles.push('support');
    }
    if (state.isRoid) {
      roles.push('roid');
    }
    return roles;
  },
  [IS_ADMIN](state: UserState): boolean {
    return state.isAdmin;
  },
  [IS_ROID](state: UserState): boolean {
    return state.isRoid;
  },
  [IS_SUPPORT](state: UserState): boolean {
    return state.isSupport;
  },
  [IS_ADMIN_OR_ROID](state: UserState): boolean {
    return state.isAdmin || state.isRoid;
  },
  [IS_ADMIN_OR_SUPPORT](state: UserState): boolean {
    return state.isAdmin || state.isSupport;
  },
  [LAST_CHECKED_TASKS](state: UserState): number | null {
    return state.tasksInfo.lastCheckedTasks;
  },
  [BADGE_STATES](state: UserState): BadgeStates {
    return state.tasksInfo.badges.states;
  },
  [BADGE_TIMEOUT_REF](state: UserState): number | null {
    return state.tasksInfo.badges.timeoutRef;
  },
  [LATEST_USER_TRIGGERED_TASK_TIME](state: UserState): string | null {
    return state.tasksInfo.latestUserTriggeredTaskTime;
  },
  [SETTINGS_DARK_THEME](state: UserState): boolean {
    return state.settings.theme.dark;
  },
  [SETTINGS_NUMBER_OF_ITEMS_IN_HISTORY](state: UserState): number {
    return state.settings.numberOfItemsInHistory;
  },
  [BADGE_STATES_LAST_CHANGED](state: UserState): number | null {
    return state.tasksInfo.badges.lastChanged;
  },
};

const mutations = {
  [SET_USER_INFO](state: UserState, userInfo: User) {
    state.user = userInfo;
    state.isAdmin = userInfo.groups.includes('admin');
    state.isRoid =
      userInfo.groups.filter((groupName) => groupName.startsWith('campus_'))
        .length > 0;
    state.isSupport = userInfo.groups.includes('support');
    Sentry.configureScope((scope) => {
      scope.setUser({
        username: state.user!.username,
        email: state.user!.email,
      });
    });
  },
  [RESET_STATE](state: UserState) {
    // NOTE: we don't reset lastCheckedTasks, otherwise when the user would log
    // out he wouldn't persist that data on the next login
    state.user = null;
    state.isAdmin = false;
    state.isRoid = false;
    state.isSupport = false;
    if (state.tasksInfo.badges.timeoutRef !== null) {
      window.clearTimeout(state.tasksInfo.badges.timeoutRef);
      state.tasksInfo.badges.timeoutRef = null;
    }
    state.tasksInfo.badges = {
      states: getInitialBadgeStates(),
      timeoutRef: null,
      lastChanged: null,
    };
    Sentry.configureScope((scope) => {
      scope.setUser({});
    });
  },
  [SET_THEME_DATA](state: UserState, theme: Theme) {
    state.settings.theme = theme;
  },
  [SET_NUMBER_OF_ITEMS_IN_HISTORY](state: UserState, number_of_items: number) {
    state.settings.numberOfItemsInHistory = number_of_items;
  },
  [SET_LAST_CHECKED_TASKS](state: UserState, lastCheckedTasks: number) {
    state.tasksInfo.lastCheckedTasks = lastCheckedTasks;
  },
  [SET_BADGE_STATES](state: UserState, badgeStates: BadgeStates) {
    state.tasksInfo.badges.states = badgeStates;
  },
  [SET_LATEST_USER_TRIGGERED_TASK_TIME](state: UserState, time: string | null) {
    state.tasksInfo.latestUserTriggeredTaskTime = time;
  },
  [SET_BADGE_STATES_LAST_CHANGED](state: UserState, val: number | null) {
    state.tasksInfo.badges.lastChanged = val;
  },
};

const actions: any = {
  [FETCH_USER_INFO]({ commit, dispatch, getters }: any) {
    return fetchRepeatedly(repositories.people.user.getUserInfo).then(
      (response: AxiosResponse<User>) => {
        commit(SET_USER_INFO, response.data);
        dispatch(FETCH_TASKS_STATE);
      },
    );
  },
  [CLEAR_USER_STATE]({ commit }: any) {
    // we never clear settings, because localstorage is the only place
    // where we store user's settings so we don't want to lose them
    commit(RESET_STATE);
  },
  [CHANGE_THEME]({ commit }: any, theme: Theme) {
    commit(SET_THEME_DATA, theme);
  },
  [CHANGE_NUMBER_OF_ITEMS_IN_HISTORY](
    { commit, dispatch }: any,
    number_of_items: number,
  ) {
    commit(SET_NUMBER_OF_ITEMS_IN_HISTORY, number_of_items);
    dispatch(
      `${HISTORY_NAMESPACE_PATH}/${UPDATE_NUMBER_OF_ITEMS_IN_HISTORY}`,
      null,
      { root: true },
    );
  },
  [UPDATE_LAST_CHANGED_TASKS]({ commit }: any, lastCheckedTasks: number) {
    commit(SET_LAST_CHECKED_TASKS, lastCheckedTasks);
  },
  async [FETCH_TASKS_STATE]({ commit, state, getters, dispatch }: any) {
    if (!state.user || getters[ROLES].length === 0) {
      return;
    }
    if (!state.tasksInfo.lastCheckedTasks) {
      commit(SET_LAST_CHECKED_TASKS, new Date().getTime());
    }
    // clear existing timeout because the fetching might have been triggered manually
    if (state.tasksInfo.badges.timeoutRef !== null) {
      window.clearTimeout(state.tasksInfo.badges.timeoutRef);
    }
    try {
      const { data } = await repositories.orchestrator.task.getTasksState(
        state.tasksInfo.lastCheckedTasks,
      );
      const tasksState: TasksState = data.state;
      // transform tasks state (data) to badge state
      const badgeStates: BadgeStates = getInitialBadgeStates();
      badgeStates.succeeded.value = tasksState.succeeded + '';
      badgeStates.failed.value = tasksState.failed + '';
      badgeStates.revoked.value = tasksState.revoked + '';
      badgeStates.ongoing.value = tasksState.ongoing + '';
      badgeStates.pending.value = tasksState.pending + '';
      const oldBadgeStates = state.tasksInfo.badges.states;
      const previousTotalTasks =
        parseInt(oldBadgeStates.succeeded.value) +
        parseInt(oldBadgeStates.failed.value) +
        parseInt(oldBadgeStates.revoked.value) +
        parseInt(oldBadgeStates.ongoing.value) +
        parseInt(oldBadgeStates.pending.value);
      const newTotalTasks =
        tasksState.succeeded +
        tasksState.failed +
        tasksState.revoked +
        tasksState.ongoing +
        tasksState.pending;
      const previousLastChanged = state.tasksInfo.badges.lastChanged;
      if (importantBadgeStateChange(oldBadgeStates, badgeStates)) {
        commit(SET_BADGE_STATES_LAST_CHANGED, new Date().getTime());
      }
      commit(SET_BADGE_STATES, badgeStates);
      // when a task goes from pending to ongoing (or is even finished) then
      // we still get new latestUserTriggeredTaskTime, so we don't want to flash
      // when an existing pending task is just moved to some other state.
      // Theoretically there are some edge cases where it might not flash when
      // it would need to, but should not be seen in practice. Since we don't
      // persist badgestate in vuex on logout we need to check whether this is
      // the first time the user is fetching it - we do that with previousLastChanged
      if (
        data.latest_user_triggered_task_time !==
          state.tasksInfo.latestUserTriggeredTaskTime &&
        newTotalTasks > previousTotalTasks &&
        previousLastChanged !== null
      ) {
        commit(
          SET_LATEST_USER_TRIGGERED_TASK_TIME,
          data.latest_user_triggered_task_time,
        );
      }
    } catch (error: any) {
      if (error.response?.status === 401) {
        // user is logged out, could happen when he logs out of AAI through a
        // different AAI app
        dispatch(
          `${AUTH_NAMESPACE_PATH}/${LOGOUT}`,
          { backend: false },
          { root: true },
        );
      }
    }
    state.tasksInfo.badges.timeoutRef = window.setTimeout(
      () => this.dispatch(`${USER_NAMESPACE_PATH}/${FETCH_TASKS_STATE}`),
      FETCH_TASKS_INTERVAL,
    );
  },
};

export default {
  namespaced: true,
  state: userState,
  getters,
  mutations,
  actions,
};
