// global - all store modules should have this mutation
export const RESET_STATE = 'resetModuleState';
export const SET_MODAL = 'setModal';
export const SET_LOADING = 'setLoading';

// auth
export const LOGIN_REQUEST = 'loginRequest';
export const LOGIN_SUCCESS = 'loginSuccess';
export const LOGIN_ERROR = 'loginError';
export const LOGOUT_REQUEST = 'logoutRequest';
export const LOGOUT_SUCCESS = 'logoutSuccess';

// user
export const SET_USER_INFO = 'setUserInfo';
export const SET_THEME_DATA = 'setThemeData';
export const SET_NUMBER_OF_ITEMS_IN_HISTORY = 'setNumberOfItemsInHistory';
export const SET_LAST_CHECKED_TASKS = 'setLastCheckedTasks';
export const SET_BADGE_STATES = 'setBadgeStates';
export const SET_LATEST_USER_TRIGGERED_TASK_TIME =
  'setLatestUserTriggeredTaskTime';
export const SET_BADGE_STATES_LAST_CHANGED = 'setBadgeStatesLastChanged';

// circuits
export const SET_CIRCUITS = 'setCircuits';
export const ADD_CIRCUIT = 'addCircuit';
export const REMOVE_CIRCUIT = 'removeCircuit';

// history
export const ADD_CAMPUS_VISITED = 'addCampusVisited';
export const ADD_SITE_VISITED = 'addSiteVisited';

export const REMOVE_CAMPUS_VISITED = 'removeCampusVisited';
export const REMOVE_SITE_VISITED = 'removeSiteVisited';

export const CLEAR_CAMPUS_VISITED = 'clearCampusVisited';
export const CLEAR_SITE_VISITED = 'clearSiteVisited';

export const POP_LAST_CAMPUS_ITEM = 'popLastCampusItem';
export const POP_LAST_SITE_ITEM = 'popLastSiteItem';

// app
export const SET_SELECTED_CAMPUS = 'setSelectedCammpus';
export const SET_SELECTED_CAMPUS_SLUG = 'setSelectedCammpusSlug';
export const SET_NAVBAR_ITEMS = 'setNavbarItems';
export const SET_DRAWER_STATE = 'setDrawerState';
