
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { repositories } from '@/api/ApiFactory';
import {
  Campus,
  CircuitExtended,
  Site,
  TaskDeviceActions,
  Device,
} from '@/api/interfaces';
import ErrorHandler from '@/components/shared/errorHandler';
import debounce from 'lodash.debounce';
import { DataTableHeader } from 'vuetify';
import SiteDocs from '@/components/connectivity/SiteDocs.vue';
import ListDocuments from '@/components/shared/ListDocuments.vue';
import DocEditor from '@/components/shared/editor/DocEditor.vue';
import { namespace } from 'vuex-class';
import { IS_ADMIN } from '@/store/getters.type';
import {
  CIRCUITS_NAMESPACE_PATH,
  USER_NAMESPACE_PATH,
  HISTORY_NAMESPACE_PATH,
} from '@/store/namespaces.type';
import {
  ADD_SITE_TO_VISITED_HISTORY,
  DELETE_CIRCUIT,
  FETCH_TASKS_STATE,
} from '@/store/actions.type';
import { historyItem } from '@/store/types';
import { sloveneStatusNames } from '@/utils/constants';
import translateWord from '@/components/shared/translations';
import {
  ModifyInterfacesComponentMode,
  supportedModifyInterfacesDeviceFunctions,
} from '@/components/shared/helpers';
import _get from 'lodash.get';

const circuitsModule = namespace(CIRCUITS_NAMESPACE_PATH);
const userModule = namespace(USER_NAMESPACE_PATH);
const historyModule = namespace(HISTORY_NAMESPACE_PATH);

@Component({
  components: {
    SiteDocs,
  },
})
export default class SiteDetail extends Vue {
  @Prop() private siteSlug!: string;
  @Prop({ default: null }) private docSlug!: string | null;
  @userModule.Getter(IS_ADMIN) public isAdmin!: boolean;
  @circuitsModule.Action(DELETE_CIRCUIT) private deleteCircuitAction!: (
    circuit: CircuitExtended,
  ) => Promise<any>;
  @userModule.Action(FETCH_TASKS_STATE)
  public fetchTasksStateAction!: () => Promise<any>;

  @historyModule.Action(ADD_SITE_TO_VISITED_HISTORY)
  public addSiteToVisitedHistory!: (item: historyItem) => Promise<any>;

  private site: Site | null = null;
  private devicesWithPosibleTaskActions: TaskDeviceActions[] = [];
  private supportedModifyInterfacesDeviceFunctions =
    supportedModifyInterfacesDeviceFunctions;
  private ModifyInterfacesComponentMode = ModifyInterfacesComponentMode;
  private sloveneStatusNamesRef = sloveneStatusNames;
  private translateWord = translateWord;
  private loading = {
    initial: true,
  };
  private search = '';
  private debouncedSearch = '';
  private debouncedUpdateDebouncedSearch = debounce(
    this.updateDebouncedSearch,
    300,
  );
  private headersDevices: DataTableHeader[] = [
    {
      text: 'Ime',
      align: 'start',
      sortable: true,
      value: 'name',
    },
    {
      text: 'Model',
      align: 'start',
      sortable: true,
      value: 'asset.product.name',
    },
    {
      text: 'Status',
      align: 'start',
      sortable: true,
      value: 'status',
    },
    {
      text: 'Funkcija',
      align: 'start',
      sortable: true,
      value: 'function.name',
    },
    {
      text: 'Vloge',
      align: 'start',
      sortable: true,
      value: 'roles',
    },
    {
      text: 'Akcije',
      align: 'end',
      sortable: true,
      value: 'actions',
    },
  ];
  private headersCircuits: DataTableHeader[] = [
    {
      text: 'Kampus',
      align: 'start',
      sortable: true,
      value: 'campus.slug',
      // width: '30%',
    },
    { text: 'Tip', value: 'type', align: 'start', sortable: false },
    {
      text: 'Vozliščna naprava',
      align: 'start',
      sortable: true,
      value: 'upstream',
      // width: '35%',
    },
    { text: 'Kampus naprava', value: 'campus', sortable: false },
    { text: 'Status', value: 'status', align: 'start', sortable: false },
    { text: 'Kontrola', value: 'control', align: 'start', sortable: false },
  ];
  private headersNetworks: DataTableHeader[] = [
    {
      text: 'Ime omrežja',
      align: 'start',
      sortable: true,
      value: 'name',
      width: '15%',
    },
    {
      text: 'Vlan ID',
      align: 'start',
      sortable: true,
      value: 'vlan.vid',
      width: '5%',
    },
    {
      text: 'Vlan ime',
      align: 'start',
      sortable: true,
      value: 'vlan.name',
      width: '15%',
    },
    {
      text: 'Kampus',
      align: 'start',
      sortable: true,
      value: 'campus.slug',
      width: '15%',
    },
    {
      text: 'Vozliščna naprava',
      align: 'start',
      sortable: true,
      value: 'l3_devices',
      width: '15%',
    },
    {
      text: 'Podomrežja',
      align: 'start',
      sortable: true,
      value: 'subnets',
      width: '15%',
    },
    {
      text: 'DHCP Strežniki',
      align: 'start',
      sortable: true,
      value: 'dhcp_servers',
      width: '20%',
    },
  ];

  private async getSite() {
    try {
      const { data } = await repositories.connectivity.site.getSite(
        this.siteSlug,
      );

      const deviceNames = data.devices?.map((device) => device.name) || [];
      for (const network of data.networks) {
        if (!network.vlan) {
          network.l3_devices = '[ni naprav]';
        } else {
          network.l3_devices = network.vlan.l3_interfaces
            .map((el) => el.device.name)
            .filter((deviceName) => deviceNames.includes(deviceName))
            .join(', ');
        }
      }
      this.site = data;
      this.addSiteToVisitedHistory({
        value: data.slug,
        description: data.name,
      });
    } catch (error) {
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { itemMessageText: 'vozlišču' },
        ).toString(),
      );
    }
  }

  private async getSiteDevicesForTasks() {
    try {
      const { data } =
        await repositories.orchestrator.site.getSiteDevicesForTasks(
          this.siteSlug,
        );
      this.devicesWithPosibleTaskActions = data;
    } catch (error) {
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { itemMessageText: 'napravah.' },
        ).toString(),
      );
    }
  }

  private get siteDevices() {
    if (!this.site || (this.site.devices && this.site.devices.length === 0)) {
      return [];
    }
    return this.site.devices?.map((device) => {
      return {
        ...device,
        inventory_sys_id: _get(device, 'asset.inventory_sys_id', null),
        actions:
          this.devicesWithPosibleTaskActions.find(
            (el) => el.instance.name === device.name,
          )?.actions || [],
      };
    });
  }

  private async created() {
    this.loading.initial = true;
    await Promise.all([this.getSite(), this.getSiteDevicesForTasks()]);

    this.loading.initial = false;
  }

  @Watch('search')
  /**
   * @description debounce of search is implemented with 2 variables, where
   * the debouncedSearch is debounced for X time from the search change
   */
  private onSearchChange() {
    this.debouncedUpdateDebouncedSearch();
  }
  private updateDebouncedSearch() {
    this.debouncedSearch = this.search;
  }

  private async prepareDevice(device: any) {
    try {
      const resp = await repositories.orchestrator.site.runTaskAction(
        this.siteSlug,
        'prepare_devices',
        [device.name],
      );
      this.fetchTasksStateAction();
      if (resp.data.detail) {
        this.$toasted.info(resp.data.detail);
      }
    } catch (error) {
      this.$toasted.error(new ErrorHandler({ error, status: true }).toString());
    }
  }

  private async configureDevice(device: any) {
    try {
      const resp = await repositories.orchestrator.site.runTaskAction(
        this.siteSlug,
        'configure_devices',
        [device.name],
      );
      this.fetchTasksStateAction();
      if (resp.data.detail) {
        this.$toasted.info(resp.data.detail);
      }
    } catch (error) {
      this.$toasted.error(new ErrorHandler({ error, status: true }).toString());
    }
  }

  private showDeviceChangeStatusModal(device: Device) {
    const promise: Promise<string> = this.$modals.open(
      'app-devices-change-status-modal',
      {
        component: {
          props: {
            devices: [device],
          },
        },
        dialog: {
          props: {
            'max-width': '600px',
          },
        },
      },
    ) as Promise<string>;
    // update device info without fetching
    promise.then((status: string) => {
      device.status = status;
    });
  }

  private showDeviceEditModal(device: Device) {
    const promise: Promise<Device> = this.$modals.open(
      'app-device-edit-modal',
      {
        component: {
          props: {
            device,
            siteEditMode: true,
          },
        },
        dialog: {
          props: {
            'max-width': '600px',
          },
        },
      },
    ) as Promise<Device>;
    // update device info without fetching
    promise.then((modifiedDevice: Device) => {
      device.name = modifiedDevice.name;
      device.primary_address = modifiedDevice.primary_address;
      device.description = modifiedDevice.description;
      device.managed = modifiedDevice.managed;
      device.location = modifiedDevice.location;
    });
  }

  private openEditCircuit(circuit: CircuitExtended) {
    if (circuit.campus) {
      this.$router.push({
        name: 'editCircuit',
        params: {
          campusSlug: (circuit.campus as Campus).slug,
          circuitId: circuit.id + '',
        },
      });
    } else {
      this.$router.push({
        name: 'edit-global-circuit',
        params: { circuitId: circuit.id + '' },
      });
    }
  }

  private openDeleteCircuitModal(circuit: CircuitExtended) {
    let upstreamInterfaceRepr = '[neznan upstream]';
    if (circuit?.termination_a?.interface) {
      upstreamInterfaceRepr = circuit.termination_a.interface.name;
    }
    let downstreamInterfaceRepr = '[neznan downstream]';
    if (circuit?.termination_z?.interface) {
      downstreamInterfaceRepr = circuit.termination_z.interface.name;
    }

    this.$modals.open('app-confirm-delete', {
      component: {
        props: {
          data: {
            repr: `povezavo med ${upstreamInterfaceRepr} in ${downstreamInterfaceRepr}`,
            item: circuit,
          },
          deleteFn: this.deleteCircuit,
        },
      },
      dialog: {
        props: {
          'max-width': '600px',
        },
      },
    });
  }

  private deleteCircuit(modal: any, circuit: CircuitExtended) {
    this.deleteCircuitAction(circuit)
      .then(() => {
        // fetch tasks state so that it immediatelly updates the badges
        this.fetchTasksStateAction();
        this.site!.circuits = this.site!.circuits.filter(
          (c) => c.id !== circuit.id,
        );
        this.$modals.close();
      })
      .catch((error) => {
        this.$toasted.error(
          new ErrorHandler(
            { error, status: true },
            { message: 'Med brisanjem povezave je prišlo do napake.' },
          ).toString(),
        );
        modal.deleting = false;
      });
  }

  beforeRouteLeave(to: any, from: any, next: any): void {
    // NOTE: beforeRouteLeave must be on the View itself, not on the nested components
    // check for unsaved changes in the documents

    // We set the default value to empty array, because when there are no
    // existing documents it returns undefined which causes page to crash
    const docs =
      (((this.$refs.siteDocs as SiteDocs)?.$refs?.docList as ListDocuments)
        ?.$refs['docEditor'] as DocEditor[]) || [];

    const unsavedDocs = docs
      .filter((docEditor) => docEditor.docHasChanges())
      .map((docEditor) => docEditor.document.name);

    if (unsavedDocs.length === 0) {
      next();
    } else {
      const answer = window.confirm(
        `Spodnji dokumenti imajo neshranjene spremembe:\n${unsavedDocs
          .map((el) => '-  ' + el)
          .join('\n')}\nSte prepričani, da želite zapustiti stran?`,
      );
      if (answer) {
        next();
      } else {
        next(false);
      }
    }
  }
}
