
import { Component, Prop, Vue } from 'vue-property-decorator';
import ErrorHandler from '@/components/shared/errorHandler';
import { Campus } from '@/api/interfaces';
import { repositories } from '@/api/ApiFactory';
import CampusHeader from '@/components/campus/CampusHeader.vue';
import Breadcrumbs from '@/views/Breadcrumbs.vue';
import { namespace } from 'vuex-class';
import {
  APP_NAMESPACE_PATH,
  HISTORY_NAMESPACE_PATH,
  USER_NAMESPACE_PATH,
} from '@/store/namespaces.type';
import { GET_DRAWER, IS_ADMIN_OR_SUPPORT } from '@/store/getters.type';
import { historyItem } from '@/store/types';
import {
  ADD_CAMPUS_TO_VISITED_HISTORY,
  SET_CAMPUS_NAVBAR,
  CLEAR_APP_STATE,
  SET_CAMPUS_DATA,
  SET_DRAWER,
} from '@/store/actions.type';

const appModule = namespace(APP_NAMESPACE_PATH);
const historyModule = namespace(HISTORY_NAMESPACE_PATH);
const userModule = namespace(USER_NAMESPACE_PATH);

@Component({
  components: {
    'app-campus-header': CampusHeader,
    'breadcrumbs': Breadcrumbs,
  },
})
export default class CampusItem extends Vue {
  @Prop() private campusSlug!: string;
  @appModule.Action(CLEAR_APP_STATE)
  public clearAppStateAction!: () => Promise<any>;
  @appModule.Action(SET_CAMPUS_NAVBAR)
  public setCampusNavbar!: (campusSlug: string) => Promise<any>;
  @appModule.Action(SET_DRAWER)
  public setDrawer!: (value: boolean) => Promise<any>;
  @appModule.Action(SET_CAMPUS_DATA)
  public setCampusData!: (campus: Campus) => Promise<any>;

  @appModule.Getter(GET_DRAWER)
  public drawer!: () => Promise<boolean>;
  @userModule.Getter(IS_ADMIN_OR_SUPPORT) public isAdminOrSupport!: boolean;

  @historyModule.Action(ADD_CAMPUS_TO_VISITED_HISTORY)
  public addCampusToVisitedHistory!: (item: historyItem) => Promise<any>;

  private campus: Campus | null = null;
  private noItems = 8;
  private loading = true;
  private panel = [] as boolean[];

  private async fetchCampusInfo(): Promise<void> {
    this.loading = true;
    try {
      const response = await repositories.tenants.campus.getCampusExtended(
        this.campusSlug,
      );
      this.campus = response.data;
      if (this.isAdminOrSupport) {
        this.addCampusToVisitedHistory({
          value: this.campus.slug,
          description: this.campus.name,
        });
      }
      this.setCampusData(this.campus);
    } catch (error) {
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { itemMessageText: 'kampusu' },
        ).toString(),
      );
    }
    this.loading = false;
  }

  private created() {
    this.fetchCampusInfo();
    this.setCampusNavbar(this.campusSlug);
  }
  private destroyed() {
    this.clearAppStateAction();
  }

  private onCampusChanged(slug: string) {
    // update url and refresh the whole page. By default vue doesn't update
    // props when route params change so that's an easy solution
    const newRoute = this.$router.resolve({
      name: 'campusGeneral',
      params: { campusSlug: slug },
    });
    window.location.href = window.location.origin + newRoute.route.fullPath;
  }
}
