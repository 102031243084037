
import { Component, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { USER_NAMESPACE_PATH } from '@/store/namespaces.type';
import { SETTINGS_NUMBER_OF_ITEMS_IN_HISTORY } from '@/store/getters.type';
import { historyItem } from '@/store/types';
const userModule = namespace(USER_NAMESPACE_PATH);

@Component
export default class VisitedHistoryBar extends Vue {
  @Prop({ type: Array, required: true, default: [] }) items!: historyItem[];
  @userModule.Getter(SETTINGS_NUMBER_OF_ITEMS_IN_HISTORY)
  public numberOfItemsInHistory!: boolean;

  private emitSelectItem(item: historyItem) {
    this.$emit('selectItem', item);
  }

  private emitRemoveItem(item: historyItem) {
    this.$emit('removeItem', item);
  }
}
