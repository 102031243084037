import {
  ADD_CAMPUS_TO_VISITED_HISTORY,
  ADD_SITE_TO_VISITED_HISTORY,
  UPDATE_NUMBER_OF_ITEMS_IN_HISTORY,
} from '../actions.type';
import {
  ADD_CAMPUS_VISITED,
  ADD_SITE_VISITED,
  CLEAR_CAMPUS_VISITED,
  CLEAR_SITE_VISITED,
  POP_LAST_CAMPUS_ITEM,
  POP_LAST_SITE_ITEM,
  REMOVE_CAMPUS_VISITED,
  REMOVE_SITE_VISITED,
} from '../mutations.type';
import { CAMPUS_VISITED_HISTORY, SITE_VISITED_HISTORY } from '../getters.type';
import { VisitedHistory, historyItem } from '../types';

const historyState: VisitedHistory = {
  campuses: [],
  sites: [],
};

const getters = {
  [CAMPUS_VISITED_HISTORY](state: VisitedHistory): historyItem[] {
    return state.campuses;
  },
  [SITE_VISITED_HISTORY](state: VisitedHistory): historyItem[] {
    return state.sites;
  },
};

const mutations = {
  [ADD_CAMPUS_VISITED](state: VisitedHistory, item: historyItem) {
    if (
      Object.prototype.hasOwnProperty.call(item, 'value') &&
      Object.prototype.hasOwnProperty.call(item, 'description')
    ) {
      state.campuses = [item, ...state.campuses];
    }
  },
  [ADD_SITE_VISITED](state: VisitedHistory, item: historyItem) {
    if (
      Object.prototype.hasOwnProperty.call(item, 'value') &&
      Object.prototype.hasOwnProperty.call(item, 'description')
    ) {
      state.sites = [item, ...state.sites];
    }
  },
  [POP_LAST_CAMPUS_ITEM](state: VisitedHistory, n = -1) {
    state.campuses.splice(n);
  },
  [POP_LAST_SITE_ITEM](state: VisitedHistory, n = -1) {
    state.sites.splice(n);
  },
  [REMOVE_CAMPUS_VISITED](state: VisitedHistory, key: string) {
    state.campuses = state.campuses.filter((item) => item.value !== key);
  },
  [REMOVE_SITE_VISITED](state: VisitedHistory, key: string) {
    state.sites = state.sites.filter((item) => item.value !== key);
  },
};

const actions: any = {
  [ADD_CAMPUS_TO_VISITED_HISTORY](
    { commit, state, rootGetters }: any,
    item: historyItem,
  ) {
    commit(REMOVE_CAMPUS_VISITED, item.value);
    commit(ADD_CAMPUS_VISITED, item);
    if (
      rootGetters['user/settings']['numberOfItemsInHistory'] <
      state.campuses.length
    ) {
      commit(POP_LAST_CAMPUS_ITEM);
    }
  },
  [ADD_SITE_TO_VISITED_HISTORY](
    { commit, state, rootGetters }: any,
    item: historyItem,
  ) {
    commit(REMOVE_SITE_VISITED, item.value);
    commit(ADD_SITE_VISITED, item);

    if (
      rootGetters['user/settings']['numberOfItemsInHistory'] <
      state.sites.length
    ) {
      commit(POP_LAST_SITE_ITEM);
    }
  },
  [UPDATE_NUMBER_OF_ITEMS_IN_HISTORY]({ commit, state, rootGetters }: any) {
    const settingsHistorySize =
      rootGetters['user/settings']['numberOfItemsInHistory'];
    const numberOfCampusItemsInHistory = state.campuses.length;
    if (settingsHistorySize < numberOfCampusItemsInHistory) {
      commit(
        POP_LAST_CAMPUS_ITEM,
        settingsHistorySize - numberOfCampusItemsInHistory,
      );
    }

    const numberOfSiteItemsInHistory = state.sites.length;
    if (settingsHistorySize < numberOfSiteItemsInHistory) {
      commit(
        POP_LAST_SITE_ITEM,
        settingsHistorySize - numberOfSiteItemsInHistory,
      );
    }
  },
};

export default {
  namespaced: true,
  state: historyState,
  getters,
  mutations,
  actions,
};
