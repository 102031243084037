var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('breadcrumbs')],1),_c('v-col',{staticClass:"pa-3"},[_c('v-card',[_c('v-toolbar',{staticClass:"font-weight-bold",attrs:{"color":"subsecondary","extended":"","extension-height":!_vm.$vuetify.breakpoint.mdAndUp ? 105 : 85},scopedSlots:_vm._u([{key:"extension",fn:function(){return [_c('v-container',{staticClass:"pa-0 fill-height"},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"text-subtitle-1 pa-0 text-center"},[_vm._v(" Kampusi so lokalna omrežja članic, katera upravlja Arnes. ")]),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"font-weight-normal",attrs:{"solo":"","rounded":"","clearable":"","prepend-inner-icon":"mdi-magnify","hide-details":"","label":"Išči","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1)]},proxy:true}])},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-domain")]),_c('v-toolbar-title',[_vm._v("Kampus omrežja")]),_c('v-spacer'),(_vm.isAdmin)?_c('v-btn',{attrs:{"small":"","color":"accent","fab":_vm.$vuetify.breakpoint.xsOnly,"rounded":_vm.$vuetify.breakpoint.smAndUp},on:{"click":function($event){return _vm.routeToCampusAdd()}}},[_c('v-icon',[_vm._v("mdi-plus-thick")]),(!_vm.$vuetify.breakpoint.xsOnly)?_c('span',[_vm._v(" Dodaj kampus ")]):_vm._e()],1):_vm._e()],1),(
            _vm.campusVisitedHistory != null &&
            _vm.campusVisitedHistory.length > 0 &&
            _vm.isAdminOrSupport
          )?_c('visited-history-bar',{attrs:{"items":_vm.campusVisitedHistory},on:{"selectItem":function($event){return _vm.routeToCampus($event)},"removeItem":function($event){return _vm.removeItemFromVisitedHistory($event.value)}}}):_vm._e(),_c('v-data-table',{staticClass:"elevation-1 pointer",attrs:{"headers":_vm.headers,"items":_vm.campuses,"server-items-length":_vm.totalCampuses,"footer-props":{ 'items-per-page-options': _vm.rowsPerPageItems },"loading":_vm.loading,"options":_vm.options},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.routeToCampus},scopedSlots:_vm._u([{key:`item.slug`,fn:function({ item }){return [_c('router-link',{attrs:{"to":{
                name: 'organization',
                params: { campusSlug: item.slug },
              }}},[_c('b',[_vm._v(_vm._s(item.slug))])])]}},{key:`item.organizations`,fn:function({ item }){return _vm._l((item.organizations),function(org){return _c('span',{key:item.slug + '-org-' + org.id},[_vm._v(" ["+_vm._s(org.portal_id)+"] "+_vm._s(org.name)+" "),_c('br')])})}},{key:`item.locations`,fn:function({ item }){return _vm._l((item.uniqueLocations),function(location){return _c('span',{key:item.slug + '-loc-' + location.id},[_vm._v(" "+_vm._s(location.address != null ? `${_vm.repr.addressRepr(location.address).text}, ${ _vm.repr.addressRepr(location.address).extra }` : 'Ročno izbrana lokacija zgradbe')),_c('br')])})}}],null,true)},[_c('v-progress-linear',{attrs:{"slot":"progress","indeterminate":_vm.indeterminate},slot:"progress"})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }