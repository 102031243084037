
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { USER_NAMESPACE_PATH } from '@/store/namespaces.type';
import { UserSettings, Theme } from '@/store/types';
import { USER_SETTINGS, IS_ADMIN_OR_SUPPORT } from '@/store/getters.type';
import {
  CHANGE_THEME,
  CHANGE_NUMBER_OF_ITEMS_IN_HISTORY,
} from '@/store/actions.type';

const userModule = namespace(USER_NAMESPACE_PATH);

@Component
export default class GeneralSettings extends Vue {
  @userModule.Getter(USER_SETTINGS) public userSettings!: UserSettings;
  @userModule.Getter(IS_ADMIN_OR_SUPPORT) public isAdminOrSupport!: boolean;
  @userModule.Action(CHANGE_THEME) public changeThemeAction!: (
    theme: Theme,
  ) => Promise<any>;
  @userModule.Action(CHANGE_NUMBER_OF_ITEMS_IN_HISTORY)
  public changeNumberOfItemsInHistory!: (
    number_of_items: number,
  ) => Promise<any>;

  public darkTheme = false;
  public numberOfItemsInHistory = 5;

  public created() {
    this.darkTheme = this.userSettings.theme.dark;
    this.numberOfItemsInHistory = this.userSettings.numberOfItemsInHistory;
  }

  public submit(): void {
    const newTheme: Theme = {
      dark: this.darkTheme,
    };
    this.changeThemeAction(newTheme);
    if (this.isAdminOrSupport) {
      this.changeNumberOfItemsInHistory(this.numberOfItemsInHistory);
    }
    this.$toasted.show('Nastavitve so bile shranjene', {
      icon: 'mdi-settings-outline',
      type: 'success',
    });
  }
}
