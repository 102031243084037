
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import {
  USER_NAMESPACE_PATH,
  HISTORY_NAMESPACE_PATH,
} from '@/store/namespaces.type';
import { historyItem } from '@/store/types';
import {
  IS_ADMIN,
  SETTINGS_NUMBER_OF_ITEMS_IN_HISTORY,
  SITE_VISITED_HISTORY,
} from '@/store/getters.type';
import { REMOVE_SITE_VISITED } from '@/store/mutations.type';
import { Site, DataOptions } from '@/api/interfaces';
import { repositories } from '@/api/ApiFactory';
import { Representations } from '@/components/shared/representation';
import VisitedHistoryBar from '@/views/campus/VisitedHistoryBar.vue';
import ErrorHandler from '@/components/shared/errorHandler';
import debounce from 'lodash.debounce';
import { openInNewTab } from '@/helpers';

const userModule = namespace(USER_NAMESPACE_PATH);
const historyModule = namespace(HISTORY_NAMESPACE_PATH);

@Component({
  components: {
    visitedHistoryBar: VisitedHistoryBar,
  },
})
export default class SitesList extends Vue {
  @userModule.Getter(IS_ADMIN) public isAdmin!: boolean;
  @userModule.Getter(SETTINGS_NUMBER_OF_ITEMS_IN_HISTORY)
  public numberOfItemsInHistory!: boolean;
  @historyModule.Getter(SITE_VISITED_HISTORY)
  public siteVisitedHistory!: historyItem[];

  @historyModule.Mutation(REMOVE_SITE_VISITED)
  public removeItemFromVisitedHistory!: (key: string) => void;

  private repr = new Representations();
  private indeterminate = true;
  private rowsPerPageItems = [50, 200, 500];
  private headers = [
    { text: 'Ime', value: 'name' },
    { text: 'Oznaka', value: 'slug' },
    { text: 'Št. povezav', value: 'nr_circuits' },
    { text: 'Naprave', value: 'devices', sortable: false },
    { text: 'Dokumentacija', value: 'doc_url', sortable: false },
  ];
  private search = '';
  private debouncedUpdateDebouncedSearch = debounce(
    this.updateDebouncedSearch,
    300,
  );
  private sites: Site[] = [];
  private totalSites = 0;
  private openInNewTab = openInNewTab;

  private loading = true;

  private options: DataOptions | null = null;

  private routeToSite(item: Site | historyItem) {
    const siteSlug =
      'value' in item ? (item as historyItem).value : (item as Site).slug;
    this.$router.push({
      name: 'site',
      params: { siteSlug: siteSlug },
    });
  }
  private routeToSiteAdd() {
    this.$router.push({ name: 'siteAdd' });
  }

  @Watch('options', { deep: true })
  private handleOptions() {
    this.fetchSites(this.options);
  }

  private async fetchSites(pagination: DataOptions | null) {
    this.loading = true;
    try {
      const { data } = await repositories.connectivity.site.getSites(
        pagination,
        this.search,
      );
      this.sites = data.results;
      this.totalSites = data.count;
    } catch (error) {
      this.$toasted.error(new ErrorHandler({ error, status: true }).toString());
    }
    this.loading = false;
  }

  @Watch('search')
  /**
   * @description debounce of search is implemented with 2 variables, where
   * the debouncedSearch is debounced for X time from the search change
   */
  private onSearchChange() {
    this.debouncedUpdateDebouncedSearch();
  }
  private updateDebouncedSearch() {
    // after a new search you want to be looking at the first page
    // manually change pagination object so that @update.pagination on data table gets triggered,
    // otherwise it doesn't update number of pages/items
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.options!.page = 1;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.fetchSites(this.options!);
  }
}
